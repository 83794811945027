export default {
  email_verified: {
    title: '',
    path: 'inscription',
    text: `<p>Votre compte a été vérifié avec succès.</p>`,
    button: 'Se connecter',
    route: 'Auth',
  },
  forgot_password: {

  }
};
